import React from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import { ButtonPrimary, ButtonSecondary } from 'pac-ux-components';
import InsightsDropdown from 'components/widgets/InsightsDropdown.js';
import AdminService from 'services/AdminService';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'assets/styles/add-edit-license.css';

const service = new AdminService();

const STAGE_EVALUATIONS = 'evaluation';

const tiers = [
    { value: 'Basic', label: 'Basic' },
    { value: 'Standard', label: 'Standard' }
];

// current-year to current-year + 10
const currentYear = moment.utc().year();
const years = Array.from({ length: 10 }, (_, i) => {
    return { value: i + currentYear, label: i + currentYear };
});

// 1 to 60
const expirationMonths = Array.from({ length: 60 }, (_, i) => {
    return { value: i + 1, label: i + 1 };
});

class AddEditLicense extends React.Component {
    constructor(props) {
        super(props);

        let selectedStart, selectedEnd;
        let tier, stage, airlineCode, airlineName;
        let selectedTier, selectedStage;

        if (this.props.license && this.props.license.start_unix) {
            selectedStart = new Date(moment.unix(this.props.license.start_unix).utc().format('YYYY-MM-DD') + ' ');
        }

        if (this.props.license && this.props.license.stage) {
            selectedStage = { value: this.props.license.stage, label: this.props.license.stage };
            stage = parseInt(this.props.license.stage);
        } else {
            if (this.props.stage !== STAGE_EVALUATIONS) {
                stage = 3; //deliveries stage
            }
        }

        if (this.props.license && this.props.license.expiration_unix) {
            selectedEnd = new Date(moment.unix(this.props.license.expiration_unix).utc().format('YYYY-MM-DD') + ' ');
            if (this.isReActivateAction()) {
                selectedEnd = new Date(new Date(selectedStart).setMonth(selectedStart.getMonth() + 1));
            }
        }

        if (this.props.license && this.props.license.airline_code) {
            airlineCode = this.props.license.airline_code;
        }

        if (this.props.license && this.props.license.airline_name) {
            airlineName = this.props.license.airline_name;
        }

        if (this.props.license && this.props.license.subscription_tier) {
            selectedTier = { value: this.props.license.subscription_tier, label: this.props.license.subscription_tier };
            tier = this.props.license.subscription_tier;
        }

        let tenantOptions = this.props.airlineOptions.map((item) => {
            return {
                label: item.label,
                value: { name: item.label, code: item.value }
            };
        });

        let minDate = new Date();
        minDate.setMonth(minDate.getMonth() + 1);

        this.state = {
            tenantOptions: tenantOptions,
            showSpinner: false,
            buttonText: this.getButtonText(),
            title: this.getTitle(),
            selectedTier: selectedTier,
            tier: tier,
            selectedStage: selectedStage,
            stage: stage,
            airlineCode: airlineCode,
            airlineName: airlineName,
            selectedStart: selectedStart ?? new Date(),
            selectedEnd: selectedEnd ?? minDate
        };

        this.updateLicenseState = this.updateLicenseState.bind(this);
        this.addLicense = this.addLicense.bind(this);
        this.editLicense = this.editLicense.bind(this);
    }

    getStages() {
        if (this.props.stage === STAGE_EVALUATIONS) {
            if (this.props.license && this.props.license.stage === 2) {
                // only 2 - when license state is 2.
                return [{ value: 2, label: 2 }];
            } else {
                // 1 to 2
                return Array.from({ length: 2 }, (_, i) => {
                    return { value: i + 1, label: i + 1 };
                });
            }
        } else {
            // only 3
            return [{ value: 3, label: 3 }];
        }
    }

    getTitle() {
        if (this.isEditAction() || this.isReActivateAction()) {
            if (this.props.stage === STAGE_EVALUATIONS) {
                return 'Edit Evaluation License';
            } else {
                return 'Edit Delivery License';
            }
        } else {
            if (this.props.stage === STAGE_EVALUATIONS) {
                return 'Add Evaluation License';
            } else {
                return 'Add Delivery License';
            }
        }
    }

    getButtonText() {
        if (this.isEditAction() || this.isReActivateAction()) {
            if (this.props.stage === STAGE_EVALUATIONS) {
                return 'Update evaluation license';
            } else {
                return 'Update license';
            }
        } else {
            if (this.props.stage === STAGE_EVALUATIONS) {
                return 'Add evaluation license';
            } else {
                return 'Add license';
            }
        }
    }

    updateLicenseState(selectedOption, inputType, selectedProperty) {
        if (inputType === 'airline') {
            let selectedAirline = {
                label: selectedOption.value.name,
                value: { name: selectedOption.value.name, code: selectedOption.value.code }
            };
            this.setState({
                airlineCode: selectedOption.value.code,
                airlineName: selectedOption.value.name,
                selectedAirline: selectedAirline
            });
        } else {
            if (inputType === 'stage' && (selectedOption.value === 1 || selectedOption.value === 2)) {
                this.setState({
                    [inputType]: selectedOption.value,
                    [selectedProperty]: { value: selectedOption.value, label: selectedOption.value },
                    expiration: null,
                    selectedExpiration: { value: 1, label: 1 }
                });
            } else {
                this.setState({
                    [inputType]: selectedOption.value,
                    [selectedProperty]: { value: selectedOption.value, label: selectedOption.value }
                });
            }
        }
    }

    enableAddButton() {
        let requiredKeys = ['airlineCode', 'tier', 'stage', 'selectedStart', 'selectedEnd'];
        if (this.state.stage === 1 || this.state.stage === 2) {
            requiredKeys = requiredKeys.filter((item) => {
                if (item !== 'selectedEnd') {
                    return true;
                } else {
                    return false;
                }
            });
        }

        return requiredKeys.every((key) => this.state[key] !== undefined && this.state[key] !== null);
    }

    async editLicense() {
        let { airlineName, airlineCode, tier, stage, selectedStart, selectedEnd } = this.state;

        this.setState({ showSpinner: true });

        let completeStartDate;
        let startDate = selectedStart.getDate().toString().padStart(2, '0');
        let startMonth = (selectedStart.getMonth() + 1).toString().padStart(2, '0');
        let startYear = selectedStart.getFullYear().toString();
        if (this.isReActivateAction()) {
            completeStartDate = startYear + startMonth + startDate;
        } else {
            completeStartDate = this.props.license.license_start_date;
        }

        let data = {
            subscription_tier: tier,
            stage: stage.toString(),
            license_start_date: completeStartDate
        };

        let completeEndDate;
        let endDate = selectedEnd.getDate().toString().padStart(2, '0');
        let endMonth = (selectedEnd.getMonth() + 1).toString().padStart(2, '0');
        let endYear = selectedEnd.getFullYear().toString();
        completeEndDate = endYear + endMonth + endDate;

        data['expiration'] = completeEndDate;

        let payload = {
            method: 'update-license',
            airline_code: airlineCode,
            airline_name: airlineName,
            data: data
        };

        let errorDescription = null;
        let result = null;
        try {
            result = await service.addUpdateLicense(payload);
            if (result.response.error !== undefined) {
                errorDescription = result.response.error.description;
            }
        } catch (error) {
            console.log('edit license error ##');
            errorDescription = 'default';
        }

        this.setState({ showSpinner: false });

        if (errorDescription !== null) {
            this.props.displayError('edit', errorDescription);
        } else {
            if (this.isReActivateAction()) {
                this.props.reactivateLicenseAction(
                    airlineCode,
                    tier,
                    stage.toString(),
                    completeStartDate,
                    completeEndDate,
                    result.response.data.updated_at
                );
            } else {
                this.props.editLicenseAction(
                    airlineName,
                    airlineCode,
                    tier,
                    stage.toString(),
                    completeStartDate,
                    completeEndDate,
                    result.response.data.updated_at
                );
            }
        }
    }

    async addLicense() {
        if (this.isEditAction() || this.isReActivateAction()) {
            return this.editLicense();
        }

        let { airlineName, airlineCode, tier, stage, selectedStart, selectedEnd } = this.state;
        this.setState({ showSpinner: true });

        let year = selectedStart.getFullYear();
        let month = String(selectedStart.getMonth() + 1).padStart(2, '0');
        let day = String(selectedStart.getDate()).padStart(2, '0');
        let completeStartDate = year + month + day;

        let completeEndDate;
        if (!selectedEnd instanceof Date) {
            selectedEnd = new Date(selectedStart);
            selectedEnd.setMonth(selectedEnd.getMonth() + 1);
        }
        year = selectedEnd.getFullYear();
        month = String(selectedEnd.getMonth() + 1).padStart(2, '0');
        day = String(selectedEnd.getDate()).padStart(2, '0');
        completeEndDate = year + month + day;

        let data = {
            subscription_tier: tier,
            stage: stage.toString(),
            license_start_date: completeStartDate,
            expiration: completeEndDate
        };

        let payload = {
            method: 'add-license',
            airline_code: airlineCode,
            airline_name: airlineName,
            data: data
        };

        let errorDescription = null;
        let result = null;
        try {
            result = await service.addUpdateLicense(payload);
            if (result.response.error !== undefined) {
                errorDescription = result.response.error.description;
            }
        } catch (error) {
            console.log('add license error ##');
            errorDescription = 'default';
        }

        this.setState({ showSpinner: false });

        if (errorDescription !== null) {
            this.props.displayError('add', errorDescription);
        } else {
            this.props.addLicenseAction(
                airlineName,
                airlineCode,
                tier,
                stage.toString(),
                completeStartDate,
                completeEndDate,
                result.response.data.updated_at
            );
        }
    }

    expirationEnabled() {
        return this.state.stage === 3;
    }

    isEditAction() {
        return this.props.actionType === 'edit';
    }

    isReActivateAction() {
        return this.props.actionType === 're-activate';
    }

    render() {
        let { showSpinner, buttonText } = this.state;

        let start = this.state.selectedStart ?? new Date();

        let maxDate = new Date(start);
        maxDate.setFullYear(start.getFullYear() + 5);

        let minDate = new Date(start);
        minDate.setMonth(start.getMonth() + 1);
        if (minDate < new Date()) {
            minDate = new Date();
            minDate.setMonth(minDate.getMonth() + 1);
        }

        return (
            <div>
                <div className="header">{this.state.title}</div>
                <div className="form-body">
                    <Row>
                        <Col>
                            {this.isEditAction() || this.isReActivateAction() ? (
                                <div className="airline">
                                    <span className="edit-label">Airline:</span>
                                    <span className="value">{this.state.airlineName}</span>
                                </div>
                            ) : (
                                <div>
                                    <div className="add-label">Airline</div>
                                    <InsightsDropdown
                                        options={this.state.tenantOptions}
                                        maxMenuHeight="370"
                                        onChange={(e) => this.updateLicenseState(e, 'airline', 'selectedAirline')}
                                        placeholder="Select an airline"
                                        name="airline"
                                        selectedOption={this.state.selectedAirline}
                                    />
                                </div>
                            )}
                        </Col>
                        <Col />
                    </Row>
                    <Row className="input-section">
                        <Col>
                            <div className="input-label">Tier</div>
                            <InsightsDropdown
                                options={tiers}
                                maxMenuHeight="370"
                                onChange={(e) => this.updateLicenseState(e, 'tier', 'selectedTier')}
                                placeholder="Select a tier"
                                selectedOption={this.state.selectedTier}
                                disabled={this.isEditAction() || this.isReActivateAction() ? true : false}
                            />
                        </Col>
                        {this.props.stage === STAGE_EVALUATIONS ? (
                            <Col>
                                <div className="input-label">Stage</div>
                                <InsightsDropdown
                                    options={this.getStages()}
                                    maxMenuHeight="370"
                                    onChange={(e) => this.updateLicenseState(e, 'stage', 'selectedStage')}
                                    placeholder="Select a stage"
                                    selectedOption={this.state.selectedStage}
                                />
                            </Col>
                        ) : (
                            <Col />
                        )}
                    </Row>
                    <Row className="input-section">
                        <Col>
                            <div className="input-label">License Start</div>
                            <DatePicker
                                onChange={(value) => {
                                    let end = this.state.selectedEnd;
                                    if (end < minDate) {
                                        end = minDate;
                                    }
                                    this.setState({ selectedStart: value, selectedEnd: end });
                                }}
                                value={this.state.selectedStart ?? new Date()}
                                minDate={new Date()}
                                disabled={this.isEditAction()}
                            />
                        </Col>
                        <Col>
                            <div className="input-label">License Expiration </div>
                            <DatePicker
                                onChange={(value) => {
                                    if (value) {
                                        this.setState({ selectedEnd: value });
                                    } else if (this.isEditAction()) {
                                        let originalExpiry = new Date(
                                            moment.unix(this.props.license.expiration_unix).utc().format('YYYY-MM-DD') +
                                                ' '
                                        );
                                        this.setState({ selectedEnd: originalExpiry });
                                    } else if (value == null) {
                                        this.setState({ selectedEnd: minDate });
                                    }
                                }}
                                value={minDate > this.state.selectedEnd ? minDate : this.state.selectedEnd}
                                disabled={this.expirationEnabled() ? false : true}
                                maxDate={maxDate}
                                minDate={minDate}
                            />
                        </Col>
                    </Row>
                </div>
                <div className="buttons-section">
                    <ButtonSecondary className="button-secondary" onClick={this.props.closeAction}>
                        Cancel
                    </ButtonSecondary>
                    <ButtonPrimary
                        disabled={!this.enableAddButton()}
                        className="button-primary margin-left-16"
                        onClick={this.addLicense}
                    >
                        {showSpinner ? (
                            <div>
                                <Spinner />
                            </div>
                        ) : (
                            buttonText
                        )}
                    </ButtonPrimary>{' '}
                </div>
            </div>
        );
    }
}

export default AddEditLicense;
